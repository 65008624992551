import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import * as vars from '../styles/vars'
import { gsap, Power0 } from "gsap"
import { MotionPathPlugin } from "gsap/MotionPathPlugin"
/* eslint-disable */
gsap.registerPlugin(MotionPathPlugin)

const HeroProyectos = () => {
    const texto = React.createRef();
    let [textoTween, setTextoTween] = useState(null);

    useEffect(() => {
        setTextoTween(
            gsap.to(texto.current, {
                duration: 1.5,
                ease: Power0.easeNone,
                paused: true,
                repeat: -1,
                motionPath: {
                    path: [{ left: "8px", top: "0px" }, { left: "8px", top: "-8px" }, { left: "-8px", top: "-8px" }, { left: "-8px", top: "8px" }, { left: "8px", top: "0px" }],
                }
            })
        );
        // eslint-disable-next-line
    }, []);

    const onMouseEnterHandler = () => {
        textoTween.play();
    };
    const onMouseLeaveHandler = () => {
        textoTween.pause();
    };

    return (
        <HeroSection className="hero-text">
            <div className="container">
                <h1
                    className="texto"
                    onMouseEnter={onMouseEnterHandler}
                    onMouseLeave={onMouseLeaveHandler}
                >ESTO ES LO QUE HAGO<span ref={texto}>ESTO ES LO QUE HAGO</span></h1>
            </div>
        </HeroSection>
    )
}

export default HeroProyectos

const HeroSection = styled.section`
    position: relative;
    padding-top: 180px;
    padding-bottom: 90px;
    h1{
        position: relative;
        font-family: ${vars.barlow};
        text-transform: uppercase;
        font-size: 3.063rem;
        display: block;
        z-index: 2;
        color: ${vars.green};
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: ${vars.green};
        width: 100%;
        line-height: 1.2;
        ${vars.md}{
            font-size: 4.188rem;
            line-height: 1.2;
        }
        ${vars.lg}{
            font-size: 5.625rem;
        }
        ${vars.xl}{
            font-size: 6.875rem;
        }
        ${vars.xxl}{
            font-size: 8.75rem;
        }
        span {
            position: absolute;
            font-family: ${vars.barlow};
            text-transform: uppercase;
            font-size: 3.063rem;
            display: block;
            z-index: 2;
            color: ${vars.black};
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-width: 2px;
            -webkit-text-stroke-color: ${vars.black};
            top: 0;
            left: 8px;
            width: 100%;
            line-height: 1.2;
            ${vars.md}{
                font-size: 4.188rem;
                line-height: 1.2;
            }
            ${vars.lg}{
                font-size: 5.625rem;
            }
            ${vars.xl}{
                font-size: 6.875rem;
            }
            ${vars.xxl}{
                font-size: 8.75rem;
            }
        }
    }
    
   
`