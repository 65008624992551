import React, { useState } from 'react'
import styled from '@emotion/styled'
import * as vars from '../styles/vars'
import useProyectosPage from '../hooks/useProyectosPage'
import ProyectoPreview from './proyectoPreview'

const ProjectsBox = () => {
    const resultado = useProyectosPage()
    const [proyectos] = useState(resultado)

    return (
        <>
            <ProjectSingle className="project-box archive" >
                {proyectos.map(proyecto => (
                    <ProyectoPreview 
                        key={proyecto.id}
                        proyecto={proyecto}
                    />

                ))}
            </ProjectSingle>
        </>
    )
}

export default ProjectsBox

const ProjectSingle = styled.div`
        display: block;
        position: relative;
        margin-bottom: 25px;
        width: 100%;
        h4{
            font-family: ${vars.barlow};
            position: relative;
            font-family: ${vars.barlow};
            text-transform: uppercase;
            font-size: 1.563rem;
            line-height: 31px;
            color: ${vars.green};
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: ${vars.green};
            ${vars.sm}{
                font-size: 3.188rem;
                line-height: 61px;
            }
            ${vars.md}{
                display: none;
            }
        }
        ${vars.md}{
            margin-bottom: 50px;
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            &:after{
                display: none;
            }
            &.archive{
                flex-wrap: wrap;
                .project{
                    margin-bottom: 30px;
                }
            }
        }
        ${vars.lg}{
            margin-bottom: 35px;
        }
        ${vars.xl}{
            margin-bottom: 50px;
        }
        ${vars.xxl}{
            margin-bottom: 75px;
        }
        h3{
            position: absolute;
            font-family: ${vars.barlow};
            text-transform: uppercase;
            font-size: 8.75rem;
            text-align: center;
            z-index: 2;
            color: ${vars.green};
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-width: 2px;
            -webkit-text-stroke-color: ${vars.green};
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            display: none;
            ${vars.md}{
                display: block;
                font-size: 4.7rem;
            }
            ${vars.lg}{
                font-size: 4.8rem;
            }
            ${vars.xl}{
                font-size: 5.6rem;
            }
            ${vars.xxl}{
                font-size: 7.6rem;
            }
            span{
                display: inline-block;
            }
        }
        .project{
            height: auto;
            position: relative;
            margin-bottom: 25px;
            z-index: 1;
            overflow: hidden;
            cursor: pointer;
            width: 100%;
            ${vars.md}{
                flex: 0 0 calc(50% - 15px);
                margin-bottom: 0;
            }
            .info{
                position: absolute;
                opacity: 0;
                width: 100%;
                height: 100%;
                border: 7px solid ${vars.green};
                background-color: rgba(8,8,8,.5);
                top: 0;
                left: 0;
                transition: all .3s ease-in-out;
                display: flex;
                justify-content: center;
                align-items: center;
                p{
                    color: white;
                    font-family: ${vars.barlow};
                    font-weight: 300;
                }
            }
            img{
                width: 100%;
                height: auto;
                position: relative;
            }
            &:hover{
                .info{
                    opacity: 1;
                    z-index: 2;
                }
            }
        }
`
